<template>
  <div>
    <div>
      <div
        class="d-print-block d-flex justify-space-between"
        v-if="!editHeader"
      >
        <span class="my-auto" v-if="signatureHeader">{{ signatureHeader }}</span>
        <span class="d-print-none my-auto" v-if="!signatureHeader">N/A</span>
        <v-btn class="ml-4 d-print-none" @click="editHeader = true" icon small
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </div>
      <div v-if="editHeader">
        <v-text-field v-model="signatureHeader" label="Signature header" @click:append-outer="saveHeader()" append-outer-icon="mdi-check" />
      </div>
    </div>

    <div
      @click="signMode = true"
      class="d-flex justify-center signature-pad my-2"
    >
      <div v-if="signatureSvg" style="position: absolute; margin-top: -30px; ">
        <v-img
          contain
          :aspect-ratio="16 / 9"
          max-height="100px"
          max-width="200px"
          :src="signatureSvg"
        />
      </div>
      <div
        v-else
        class="text-center font-weight-thin my-auto mx-10 d-print-none"
      >
        Sign Here <v-icon>mdi-draw-pen</v-icon>
      </div>
    </div>
    <div>
      <div
        class="d-print-block d-flex justify-space-between"
        v-if="!editFooter"
      >
        <div v-if="signatureFooter" class="d-flex justify-space-between">
          <span>( {{ signatureFooter }} )</span>
          <span class="ml-2" v-if="signature">{{ signature.updatedDate | formatDateYear }}</span>
        </div>
        <span class="d-print-none" v-if="!signatureFooter">N/A</span>
        <v-btn class="ml-4 d-print-none" @click="editFooter = true" icon small
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </div>
      <div v-if="editFooter">
        <v-text-field v-model="signatureFooter" label="Signature footer" @click:append-outer="saveFooter()" append-outer-icon="mdi-check" />
      </div>
    </div>

    <v-dialog
      v-resize="onResize"
      style="zIndex: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="signMode"
      persistent
    >
      <v-card class="pt-5">
        <v-card-subtitle class="d-flex">
          <div class="my-auto">Sign Below</div>
          <v-spacer />
          <v-btn @click="signMode = null" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-subtitle>

        <v-card-text class="px-4">
          <v-sheet :elevation="2">
            <vue-signature
              :key="windowSize.x"
              v-if="loaded"
              v-model="signatureSvg"
              :h="`${(windowSize.x / 3) < 200 ? 200:windowSize.x / 3}px`"
              :w="`${windowSize.x * .8 < 300 ? 300:windowSize.x * .8}px`"
              ref="signature"
              :sigOption="option"
            ></vue-signature>
          </v-sheet>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="ma-2" @click="saveSignature">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            <span>Done</span>
          </v-btn>
          <v-btn class="ma-2" @click="clear">
            <v-icon class="mr-2">mdi-eraser</v-icon>
            <span>Clear</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueSignature from "vue-signature";

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    signature: {
      type: Object,
      required: false,
    },
    signatureHeader: {
      type: String,
      required: false,
    },
    signatureFooter: {
      type: String,
      required: false,
    },
  },
  components: {
    VueSignature,
  },
  data: () => ({
    signMode: false,
    signatureSvg: null,
    signatureId: null,
    windowSize: {},
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
      onEnd: null,
    },
    loaded: false,
    editFooter: false,
    editHeader: false,
  }),
  mounted() {
    const parent = this;
    this.signatureSvg = this.value;
    this.signatureId = this.signature ? this.signature.id:null;
    this.option.onEnd = parent.onEnd;
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };

    this.loaded = true;
  },
  methods: {
    onEnd() {
      const isEmpty = this.$refs.signature.isEmpty();
      console.log("end stroke");
      if (!isEmpty) {
        this.empty = false;
        // var png = this.$refs.signature.save()
        // var jpeg = this.$refs.signature.save('image/jpeg')
        var svg = this.$refs.signature.save("image/svg+xml");
        this.mode = "view";
        this.signatureSvg = svg;
        this.$emit("input", this.signatureSvg);
      } else {
        this.clear();
      }
    },
    clear() {
      this.$refs.signature.clear();
      this.signatureSvg = null;
      this.empty = true;
      this.$emit("input", this.signatureSvg);
    },
    saveSignature() {
      this.signMode = null;

      const params = {
        id: this.signatureId,
        signature: this.signatureSvg,
        signatureHeader: this.signatureHeader,
        signatureFooter: this.signatureFooter,
      }
      this.$axios
        .put("/worksheetSignature/" + this.signatureId, params)
        .then((resp) => {
          console.log("save signature: ", resp);
          this.$emit("done", this.signatureSvg);
        });
      
    },
    saveFooter(){
      this.editFooter = false;
      this.saveSignature();
    },
    saveHeader(){
      this.editHeader = false;
      this.saveSignature();
    },
    undo() {
      this.$refs.signature.undo();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>
<style scoped>
.signature-pad {
  width: 100%;
  max-height: 100px;
  min-width: 100px;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
</style>
